import React from "react";

const SvgLogo = ({ color, size }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
      width={size}
      height={size}
    >
      <g>
        <g>
          <g>
            <path
              style={{ fill: color }}
              d="M72.145,273.81l91.286,22.569l-3.637,14.709L86.98,338.726l-0.187,0.758l62.18,15.373
				l-4.088,16.536l-91.286-22.569l3.658-14.798l72.849-27.583l0.199-0.802l-62.226-15.384L72.145,273.81z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M182.831,265.951c-3.415,5.981-7.691,10.412-12.827,13.295
				c-5.136,2.883-10.756,4.176-16.862,3.879c-6.106-0.296-12.32-2.251-18.648-5.865c-6.326-3.613-11.18-7.98-14.565-13.104
				c-3.385-5.122-5.141-10.629-5.266-16.517c-0.127-5.888,1.518-11.823,4.933-17.803s7.691-10.412,12.827-13.295
				c5.136-2.883,10.77-4.168,16.901-3.856c6.134,0.312,12.362,2.274,18.688,5.887c6.328,3.613,11.168,7.974,14.525,13.081
				c3.359,5.109,5.102,10.606,5.227,16.495C187.892,254.036,186.246,259.971,182.831,265.951z M171.314,259.268
				c1.853-3.243,2.503-6.466,1.955-9.671c-0.551-3.204-2.031-6.21-4.447-9.017c-2.415-2.807-5.483-5.273-9.206-7.399
				c-3.748-2.141-7.45-3.542-11.11-4.203c-3.658-0.662-7.013-0.418-10.065,0.728c-3.052,1.148-5.504,3.342-7.358,6.585
				c-1.897,3.323-2.568,6.597-2.013,9.822c0.555,3.225,2.048,6.245,4.477,9.06c2.427,2.815,5.516,5.292,9.264,7.433
				c3.723,2.126,7.404,3.515,11.05,4.169c3.644,0.654,6.99,0.397,10.037-0.771S169.417,262.591,171.314,259.268z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M139.975,171.92l10.958-12.498l49.786,43.649c1.68,1.474,3.191,2.339,4.53,2.598
				c1.34,0.259,2.545,0.104,3.614-0.465c1.069-0.569,2.057-1.372,2.964-2.407c0.687-0.782,1.261-1.552,1.719-2.309
				c0.46-0.757,0.796-1.348,1.006-1.774l11.617,6.46c-0.354,0.87-0.935,2.041-1.738,3.514c-0.803,1.474-1.96,3.117-3.466,4.929
				c-2.654,3.211-5.604,5.612-8.85,7.202c-3.246,1.591-6.615,2.148-10.106,1.672c-3.491-0.475-6.883-2.178-10.178-5.107
				L139.975,171.92z M172.809,160.1l9.667,8.475l-26.725,30.485l-9.669-8.476L172.809,160.1z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M297.796,101.621c4.998,8.815,7.628,17.29,7.894,25.425c0.266,8.135-1.485,15.472-5.257,22.008
				c-3.774,6.537-9.241,11.835-16.404,15.895c-7.163,4.061-14.521,6.024-22.073,5.889c-7.554-0.134-14.752-2.405-21.596-6.812
				c-6.844-4.407-12.755-11.004-17.737-19.792c-4.998-8.814-7.628-17.289-7.894-25.424c-0.268-8.135,1.485-15.471,5.257-22.008
				c3.771-6.536,9.239-11.834,16.402-15.896c7.163-4.06,14.518-6.03,22.063-5.909c7.545,0.122,14.74,2.385,21.584,6.792
				C286.88,86.197,292.801,92.807,297.796,101.621z M282.898,110.067c-3.517-6.204-7.455-10.897-11.807-14.077
				c-4.354-3.179-8.857-4.902-13.512-5.166c-4.653-0.265-9.204,0.863-13.651,3.384c-4.447,2.521-7.751,5.846-9.914,9.975
				c-2.163,4.129-2.999,8.878-2.506,14.246c0.491,5.369,2.496,11.155,6.013,17.36c3.517,6.205,7.452,10.897,11.807,14.076
				c4.352,3.18,8.857,4.902,13.51,5.167c4.655,0.265,9.204-0.863,13.651-3.384c4.447-2.521,7.753-5.846,9.916-9.975
				c2.163-4.129,2.999-8.878,2.506-14.246C288.419,122.058,286.415,116.272,282.898,110.067z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M330.687,99.164l9.491,40.219l-16.177,3.818L307.803,74.56l15.462-3.649l2.751,11.664l0.805-0.19
				c0.671-4.216,2.476-7.865,5.412-10.948c2.936-3.083,6.966-5.229,12.091-6.439c4.736-1.118,9.109-1.08,13.119,0.113
				c4.007,1.193,7.441,3.48,10.298,6.863c2.857,3.383,4.919,7.819,6.184,13.307l10.312,43.705l-16.175,3.818l-9.724-41.203
				c-1.083-4.587-3.114-7.899-6.094-9.931c-2.98-2.033-6.555-2.557-10.726-1.573c-2.83,0.668-5.194,1.879-7.091,3.631
				c-1.897,1.753-3.191,3.938-3.878,6.553C329.859,92.896,329.905,95.857,330.687,99.164z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M404.623,34.953l5.104,93.897l-16.596,0.902l-5.104-93.897L404.623,34.953z"
            />
          </g>
          <g>
            <path
              style={{ fill: color }}
              d="M426.256,154.712c-2.251-0.259-4.306-0.672-6.166-1.239c-1.86-0.568-3.325-1.16-4.394-1.775
				l5.301-12.379c3.471,1.445,6.469,1.967,8.996,1.563c2.524-0.404,4.801-2.446,6.825-6.126l1.849-3.624l-17.202-74.442
				l17.517,2.009l10.076,54.768l0.731,0.084l22.262-51.058l17.644,2.116l-37.146,75.232c-1.726,3.499-3.82,6.448-6.284,8.846
				c-2.462,2.398-5.333,4.125-8.612,5.182C434.373,154.927,430.574,155.207,426.256,154.712z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: color,
                }}
                d="M149.51,648.888l62.58-70.193l26.871,23.956c5.074,4.523,8.586,9.081,10.541,13.673
					c1.953,4.593,2.55,9.008,1.784,13.248c-0.766,4.239-2.698,8.096-5.793,11.568c-2.547,2.857-5.268,4.807-8.167,5.851
					c-2.901,1.044-5.837,1.37-8.806,0.976c-2.973-0.393-5.847-1.335-8.618-2.823l-0.611,0.685c2.367,2.358,4.084,5.251,5.148,8.68
					c1.064,3.431,1.205,7.054,0.428,10.871c-0.78,3.817-2.758,7.508-5.935,11.073c-3.239,3.633-7.027,6.11-11.365,7.432
					c-4.336,1.322-9.019,1.238-14.046-0.252c-5.03-1.49-10.229-4.628-15.597-9.416L149.51,648.888z M171.698,649.599l13.676,12.193
					c4.613,4.114,8.734,6.198,12.357,6.251c3.623,0.053,6.68-1.315,9.165-4.103c1.853-2.079,3.015-4.417,3.484-7.013
					c0.47-2.596,0.187-5.225-0.842-7.887c-1.032-2.662-2.86-5.165-5.488-7.507l-14.019-12.498L171.698,649.599z M198.192,619.884
					l12.577,11.214c2.193,1.955,4.533,3.312,7.015,4.069c2.483,0.758,4.898,0.841,7.251,0.251c2.348-0.59,4.442-1.913,6.275-3.97
					c2.425-2.718,3.466-5.808,3.128-9.269c-0.34-3.46-2.383-6.861-6.129-10.202l-12.853-11.458L198.192,619.884z"
              />
            </g>
            <g>
              <path
                style={{ fill: color }}
                d="M277.76,703.8l18.486-36.446l14.824,7.521l-31.906,62.9l-14.373-7.29l5.671-11.18l-0.657-0.332
					c-3.204,2.801-6.994,4.502-11.365,5.098c-4.371,0.598-8.917-0.301-13.639-2.697c-4.123-2.091-7.293-4.858-9.512-8.3
					c-2.219-3.442-3.318-7.381-3.299-11.816c0.019-4.436,1.303-9.165,3.85-14.189l20.314-40.05l14.826,7.52l-19.153,37.756
					c-2.022,3.986-2.536,7.706-1.543,11.162c0.993,3.457,3.265,6.084,6.814,7.884c2.184,1.108,4.57,1.649,7.156,1.623
					c2.589-0.026,5.081-0.761,7.478-2.205C274.129,709.314,276.14,706.995,277.76,703.8z"
              />
            </g>
            <g>
              <path
                style={{ fill: color }}
                d="M332.189,662.887l16.034,4.385l-17.468,63.868c-0.59,2.156-0.708,3.893-0.354,5.211
					c0.354,1.319,1.02,2.334,1.999,3.046c0.979,0.713,2.136,1.25,3.464,1.613c1.004,0.275,1.946,0.454,2.827,0.536
					c0.884,0.083,1.562,0.125,2.036,0.128L340,754.948c-0.937,0.061-2.244,0.053-3.919-0.025c-1.677-0.078-3.66-0.399-5.948-0.96
					c-4.049-0.98-7.499-2.583-10.349-4.806c-2.853-2.223-4.829-5.009-5.928-8.357c-1.099-3.347-1.053-7.142,0.139-11.387
					L332.189,662.887z M357.184,687.242l-3.392,12.401l-39.108-10.696l3.392-12.402L357.184,687.242z"
              />
            </g>
            <g>
              <path
                style={{ fill: color }}
                d="M412.258,763.992l-18.153,1.054l27.598-95.801l20.994-1.22l38.551,91.958l-18.153,1.054
					l-29.409-73.171l-0.733,0.042L412.258,763.992z M410.715,727.148l49.508-2.877l0.794,13.66l-49.508,2.877L410.715,727.148z"
              />
            </g>
            <g>
              <path
                style={{ fill: color }}
                d="M493.688,658.001l24,90.925l-16.071,4.242l-24-90.925L493.688,658.001z"
              />
            </g>
            <g>
              <path
                style={{ fill: color }}
                d="M571.183,671.819l-13.13,7.74c-1.02-1.219-2.288-2.224-3.806-3.018
					c-1.515-0.793-3.255-1.201-5.213-1.224s-4.109,0.495-6.453,1.552c-3.154,1.424-5.488,3.3-7.006,5.63
					c-1.518,2.33-1.77,4.578-0.761,6.741c0.817,1.882,2.193,3.091,4.13,3.627c1.939,0.537,4.711,0.444,8.313-0.276l12.024-2.605
					c6.675-1.433,12.14-1.397,16.395,0.107c4.257,1.505,7.438,4.553,9.544,9.144c1.798,4.059,2.228,8.156,1.289,12.29
					c-0.939,4.134-3.082,7.998-6.43,11.592c-3.35,3.593-7.702,6.598-13.061,9.016c-7.867,3.552-14.949,4.756-21.24,3.615
					c-6.291-1.141-11.231-4.46-14.821-9.96l14.132-8.09c1.981,2.6,4.391,4.148,7.235,4.645c2.844,0.497,6.009-0.042,9.498-1.616
					c3.598-1.624,6.159-3.669,7.681-6.138c1.525-2.467,1.795-4.789,0.812-6.965c-0.831-1.842-2.221-3.044-4.172-3.608
					c-1.95-0.563-4.551-0.499-7.797,0.194l-12.003,2.646c-6.747,1.5-12.288,1.389-16.624-0.331c-4.338-1.721-7.549-4.93-9.636-9.631
					c-1.816-3.949-2.286-7.876-1.411-11.781c0.875-3.904,2.911-7.542,6.106-10.917c3.195-3.372,7.36-6.218,12.496-8.535
					c7.533-3.401,14.192-4.475,19.979-3.224C563.039,663.69,567.685,666.818,571.183,671.819z"
              />
            </g>
            <g>
              <path
                style={{ fill: color }}
                d="M640.552,687.767c-5.437,4.229-11.08,6.698-16.927,7.408s-11.536-0.236-17.07-2.836
					c-5.532-2.599-10.534-6.774-15.007-12.525c-4.475-5.75-7.3-11.638-8.477-17.664s-0.703-11.786,1.423-17.279
					c2.124-5.493,5.905-10.355,11.342-14.584c5.437-4.229,11.08-6.699,16.927-7.408c5.847-0.71,11.545,0.248,17.098,2.872
					c5.55,2.624,10.562,6.811,15.037,12.562c4.472,5.751,7.29,11.627,8.45,17.629c1.157,6.002,0.673,11.749-1.451,17.242
					C649.769,678.677,645.989,683.538,640.552,687.767z M632.447,677.2c2.948-2.293,4.778-5.026,5.49-8.198
					c0.713-3.172,0.488-6.516-0.676-10.032c-1.164-3.516-3.063-6.965-5.694-10.348c-2.651-3.406-5.541-6.112-8.672-8.118
					c-3.13-2.005-6.326-3.058-9.586-3.161c-3.258-0.101-6.363,0.994-9.31,3.287c-3.022,2.35-4.889,5.122-5.604,8.315
					c-0.715,3.195-0.486,6.555,0.687,10.082c1.175,3.528,3.086,6.995,5.736,10.402c2.633,3.384,5.509,6.071,8.63,8.064
					c3.121,1.994,6.312,3.03,9.574,3.11C626.286,680.685,629.428,679.549,632.447,677.2z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 6.2,
              strokeMiterlimit: 10,
            }}
            d="M519.689,108.317"
          />
        </g>
        <g>
          <path
            style={{ fill: color }}
            d="M677.927,599.761l-6.483-5.171c43.613-54.675,66.665-120.623,66.665-190.712
			c0-66.203-20.792-129.212-60.127-182.216c-38.574-51.977-91.382-89.806-152.718-109.398l2.523-7.899
			c63,20.123,117.239,58.975,156.854,112.355c40.404,54.444,61.76,119.162,61.76,187.158c0,36.231-6.112,71.75-18.167,105.568
			C716.585,542.13,699.658,572.516,677.927,599.761z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgLogo;
