import React from "react"
import styled from "@emotion/styled"
import SvgLogo from "./SvgLogo"

const PopupStyles = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  .popup-inner {
    overflow: scroll;
    max-width: 700px;
    margin: auto;
    padding: 20px;
    @media (max-width: 500px) {
      padding: 10px;
    }
    .popup-inner-inner {
      background: var(--bg);
      border-radius: 20px;
      outline: none;
      border: 2px solid var(--secondary);
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 500px) {
        padding: 20px;
      }
      svg {
        margin-bottom: 20px;
        margin-top: -20px;
      }
      button {
        padding: 0px;
        line-height: 1;
        outline: none;
        border: none;
        font-size: 3rem;
        font-weight: 300;
        align-self: flex-end;
        cursor: pointer;
        color: var(--copy);
      }
      .popup-button-alt {
        font-size: inherit;
        align-self: unset;
        margin: 30px 0 0 0;
        padding: 5px;
        border-radius: 6px;
        outline: none;
        border: 2px solid var(--secondary);
        background: var(--bg);
        font-weight: 600;
      }
    }
  }
`

export default function Popup() {
  const [popup, setPopup] = React.useState(false)

  function handleClick(e) {
    localStorage.setItem("not only popup", "1")
    setPopup(false)
  }

  React.useEffect(() => {
    if (!localStorage.getItem("not only popup")) {
      setPopup(true)
    }
  })

  return (
    <>
      {popup && (
        <PopupStyles>
          <div className="popup-inner">
            <div className="popup-inner-inner">
              <button onClick={handleClick}>&times;</button>
              <SvgLogo color="var(--copy)" size={100} />
              <p>
                We started Not Only — But Also to start conversations about the
                creative industry and to open up new opportunities for young
                creatives. <br />
                <br /> Over the past year, we’ve had the privilege of connecting
                with some incredible people both in the industry and just
                starting out.{" "}
                <span role="img" alt="emoji">
                  ✨
                </span>{" "}
                We've learned a lot over that time; about ourselves, about the
                people we are hoping to help and about how we can really make
                this the best it can be. <br />
                <br />
                Not Only got off to a flying start and we quickly realised that
                there might be a more impactful way to reach the right people
                and really encourage a shift in the industry. That means, we’ve
                taken a step back over the past few months to reconsider what
                makes Not Only — But Also so great, find impactful way to reach
                the right people and how we could really encourage a shift in
                the industry.{" "}
                <span role="img" alt="emoji">
                  💥
                </span>
                <br />
                <br />
                We’ve got some fresh new ideas coming that we think could really
                shake things up, so keep tabs on us because we’ll be launching
                Not Only 2.0 soon.
                <br />
                <br /> Maddi & Guy
                <br />
                <br /> In the meantime, you can check out some of the pieces
                written by our awesome contributors.{" "}
                <span role="img" alt="emoji">
                  ✌️
                </span>
              </p>
              <button className="popup-button-alt" onClick={handleClick}>
                Close
              </button>
            </div>
          </div>
        </PopupStyles>
      )}
    </>
  )
}
